<template>
  <th-page-wrapper
    class="text-th-primary h-full"
    align-title="center"
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <supplier-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import SupplierForm from './components/form'

export default {
  components: { SupplierForm },
  computed: {
    isNew() {
      const id = this.$route.params.id
      return !id || id === 'new'
    },
    actions() {
      return {
        permissionPrefix: 'supplier_management:suppliers',
        returnedPath: { name: 'suppliers-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.handleSubmit()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
