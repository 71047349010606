import baseModel from '@/models/base-model-new'
import filterObj from 'just-filter-object'
import { isNullish } from '@/utils/general'
import { genInitialAddress } from '@/utils/address'
import { addressTypes } from '../helpers'

export default {
  setup(model) {
    const methods = {
      parseResponse(response) {
        //transform phoneNumbers from object to array
        const data = response?.data || {}

        //initial addresses if null or empty
        const addresses = data.addresses?.length
          ? data.addresses
          : [{ ...genInitialAddress(), type: addressTypes[0] }]

        //transform phoneNumbers
        const phoneNumbers = (data.phoneNumbers || []).map((item) => ({
          phone_type: item.type,
          phone_number: item.number
        }))

        return {
          ...data,
          phoneNumbers,
          addresses: addresses.map(
            ({
              postalCode: postal_code,
              streetNumber: street_number,
              ...rest
            }) => ({ postal_code, street_number, ...rest })
          )
        }
      },
      parseDataBeforeSave(data) {
        //transform phoneNumbers
        const phoneNumbers = data.phoneNumbers.reduce(
          (phones, { phone_type, phone_number }) => {
            if (phone_type && phone_number) {
              phones.push({
                number: phone_number,
                type: phone_type
              })
            }
            return phones
          },
          []
        )

        //filter only valid bank accounts
        const bankAccounts = data.bankAccounts
          .filter(({ name }) => !!name)
          .map(({ name, iban, swift }) => {
            return { name: name || '', iban: iban || '', swift: swift || null }
          })

        //filter only valid address
        const addresses = data.addresses.filter(({ street }) => !!street)

        return {
          ...data,
          email: data.email ? data.email : null,
          phoneNumbers,
          bankAccounts,
          addresses: addresses.map(
            ({
              postal_code: postalCode,
              street_number: streetNumber,
              ...rest
            }) => ({ postalCode, streetNumber, ...rest })
          )
        }
      }
    }
    return baseModel.setup(model, 'suppliers', methods)
  }
}
