<template>
  <th-page-wrapper v-loading="isLoading">
    <!-- company information  -->
    <supplier-information
      ref="supplier-information"
      v-model="current"
      :resources="resources"
    />
    <!-- products table  -->
    <supplier-products
      v-model="productIds"
      :supplier-id="id"
      :resources="resources"
    />
    <!-- company contacts  -->
    <supplier-contact ref="supplier-contact" v-model="current" />
    <!-- Bank accounts -->
    <supplier-bank-details ref="supplier-bank-details" v-model="current" />
  </th-page-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
import th from '@tillhub/javascript-sdk'
import intersect from 'just-intersect'
import remove from 'just-remove'
import { ref } from 'vue'
import { mapGetters } from 'vuex'
import supplierModel from '../model/supplier-model'
import { genInitialData } from '../helpers'
import SupplierInformation from './supplier-information'
import SupplierProducts from './supplier-products'
import SupplierContact from './supplier-contact'
import SupplierBankDetails from './supplier-bank-details'

export default {
  components: {
    SupplierInformation,
    SupplierContact,
    SupplierBankDetails,
    SupplierProducts
  },
  setup() {
    const resources = ref({})
    const productIds = ref([])
    const productIdsInitial = ref([])

    return {
      ...supplierModel.setup(genInitialData()),
      resources,
      productIds,
      productIdsInitial
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    generateSupplierNumber() {
      return safeGet(
        this.clientAccountConfiguration,
        'suppliers.generate_supplier_number',
        false
      )
    }
  },
  async mounted() {
    this.id = this.$route.params.id
    await Promise.allSettled([
      this.fetchResources(),
      this.fetchModel(),
      this.fetchRelatedProductIds()
    ])

    this.sync()
  },
  methods: {
    async fetchModel() {
      return await this.fetch()
    },
    async fetchResources() {
      this.resources = await this.$resourceFetch(
        'expenseAccounts',
        'accounts',
        'product_groups'
      )
    },

    async fetchRelatedProductIds() {
      if (!this.id) {
        return
      }

      try {
        const {
          data = { productId: [] }
        } = await th.suppliersProductsRelation().getProductIds(this.id)

        this.productIds = data.productId
        this.productIdsInitial = [...data.productId]
      } catch (error) {
        this.$logException(error)
      }
    },
    async handleSubmit() {
      const isNew = this.isNew
      this.$ampli.eventWithBaseProps(
        isNew ? 'supplierCreateButtonClick' : 'supplierSaveButtonClick'
      )
      //validate the form
      const isValid = await this.validate()
      if (!isValid) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.message.invalid_inputs')
        })
      }
      //save
      const resource = this.$t('common.resource.supplier.singular')
      const { error } = await this.saveModel()
      if (error) {
        //save fail
        const errorMessage = isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })

        this.$logException(error, {
          message: errorMessage,
          trackError: false
        })
        return
      }

      //save success
      const successMessage = isNew
        ? this.$t('common.success.action.create.single', { resource })
        : this.$t('common.success.action.update.single', { resource })

      this.$message({
        type: 'success',
        message: successMessage
      })

      if (isNew) {
        if (this.navigationAfterCreation === 'edit' && this.id) {
          this.routeTo(this.id)
        } else {
          this.routeTo()
        }
      } else {
        await Promise.all([this.fetchModel(), this.fetchRelatedProductIds()])
      }
    },
    async saveModel() {
      const { error, data } = await this.save()
      if (error) {
        return { error }
      }
      this.isLoading = true
      try {
        await this.saveSupplierToProductsRelation(data.id)
      } catch (err) {
        return { error: err }
      }
      this.isLoading = false
      return {}
    },
    async saveSupplierToProductsRelation(supplierId) {
      const notChangedIds = intersect(this.productIds, this.productIdsInitial)
      const toAddIds = remove(this.productIds, notChangedIds)
      const toDeleteIds = remove(this.productIdsInitial, notChangedIds)
      await Promise.all([
        th.suppliersProductsRelation().bulkCreate(supplierId, {
          productId: toAddIds
        }),
        th.suppliersProductsRelation().bulkDelete(supplierId, {
          productId: toDeleteIds
        })
      ])
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.companyName)
      if (confirm) this.delete()
    },
    async delete() {
      const { error } = await this.deleteModel()
      if (error) {
        //Delete failed
        const errorMessage = this.$t('common.error.action.delete.single', {
          resource: this.$t('common.resource.supplier.singular')
        })

        this.$logException(error, { message: errorMessage })
        return
      }

      //Delete success
      this.routeTo()
    },
    routeTo(path) {
      //if we arrive to supplier from other page we will return to the sender
      const redirectedFrom = this.$route.query?.redirectedFrom
      if (redirectedFrom) {
        this.$router.push(redirectedFrom)
        return
      }
      this.reset() //this will make model clean (not dirty)
      const routePath = `/supplier-management/suppliers${
        path ? '/' + path : ''
      }`
      this.$router.push(routePath)
    },
    async validate() {
      // Validate all sections
      let valid = true
      for (const key in this.$refs) {
        if (key.includes('supplier') && this.$refs[key].validate) {
          if (!(await this.validateAttribute(key))) valid = false
        }
      }
      return valid
    },
    validateAttribute(key) {
      return new Promise((resolve) => {
        this.$refs[key].validate(resolve)
      })
    }
  }
}
</script>
