<template>
  <th-wrapper
    collapsable
    class="m-6"
    :title="$t('pages.suppliers.bank_details')"
  >
    <banks-inputs ref="form" v-model="form.bankAccounts" />
  </th-wrapper>
</template>

<script>
import BanksInputs from '@/components/bank-details/banks'

export default {
  components: {
    BanksInputs
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    async validate(cd) {
      const isValid = await this.$refs['form'].validate()
      cd(isValid)
    }
  }
}
</script>
