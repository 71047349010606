<template>
  <div>
    <el-select
      id="productIds"
      v-model="productIds"
      v-cancel-read-only
      class="w-full"
      multiple
      filterable
      remote
      reserve-keyword
      :no-data-text="$t('common.data.no_data')"
      :placeholder="$t('common.inputs.placeholders.select')"
      :remote-method="searchProducts"
      :loading="isProductsSearchLoading"
      data-testid="products-select"
      @focus="handleSelectFocus"
    >
      <div ref="optionsWrapper" v-loading="isProductsSearchPaginating">
        <span v-if="!productsOptions.length">{{
          $t('common.data.no_data')
        }}</span>
        <el-option
          v-for="option in productsOptions"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
        <div data-selector="optionsEnd" />
      </div>
    </el-select>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, inject, nextTick } from 'vue'
import th from '@tillhub/javascript-sdk'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  resultFilter: {
    type: Function,
    default: (results) => results
  },
  loading: {
    type: Boolean,
    default: false
  },
  id: {
    type: String,
    default: 'product'
  }
})
const emit = defineEmits(['update:modelValue'])

const apiNext = ref(null)
const optionsWrapper = ref(null)
const logException = inject('logException')
const isScrollHandlerInited = ref(false)
const isProductsSearchLoading = ref(false)
const isProductsSearchPaginating = ref(false)
const isProductsSearchPreloadDone = ref(false)
const products = ref([])
const productIds = computed({
  get: () => {
    return props.modelValue.map((item) => item.id)
  },
  set: (val) => {
    const result = val
      .map((item) => {
        return products.value.find((product) => product.id === item)
      })
      .filter((item) => item) // sometimes when filtering by API product won't be there
    emit('update:modelValue', result)
  }
})
const productsOptions = computed(() => {
  return props.resultFilter(products.value).map((item) => {
    const label = [item.custom_id, item.name]
      .filter((i) => i)
      .join(' - ')
      .trim()
    return {
      value: item.id,
      label
    }
  })
})

const hasEnoughVisibleProducts = computed(
  () => productsOptions.value.length >= 15
)

async function handleSelectFocus() {
  if (!isProductsSearchPreloadDone.value) {
    await searchProducts()
    isProductsSearchPreloadDone.value = true
  }

  if (!isScrollHandlerInited.value) {
    nextTick(() => {
      const scroller = optionsWrapper.value.parentNode.parentNode

      isScrollHandlerInited.value = true
      scroller.addEventListener('scroll', (event) => {
        const optionsEnd = optionsWrapper.value.querySelector(
          '[data-selector="optionsEnd"]'
        )
        if (!optionsEnd) return
        if (scroller.scrollTop + scroller.offsetHeight > optionsEnd.offsetTop) {
          paginateProductsSearch()
        }
      })
    })
  }
}

async function searchProducts(q) {
  isProductsSearchLoading.value = true
  try {
    const { data = [], next } = await th.products().getAll({
      limit: 50,
      deleted: false,
      exclude_system_products: true,
      stockable: true,
      is_service: false,
      type: [
        'product',
        'variant_product',
        'linked_product',
        'composed_product'
      ],
      q
    })
    apiNext.value = next || null
    products.value = data
    if (!hasEnoughVisibleProducts.value) {
      await paginateProductsSearch()
    }
  } catch (error) {
    logException(error)
  }
  isProductsSearchLoading.value = false
}

async function paginateProductsSearch() {
  if (!apiNext.value || isProductsSearchPaginating.value) return
  isProductsSearchPaginating.value = true
  const { data, next } = await apiNext.value()
  apiNext.value = next || null
  products.value.push(...data)
  isProductsSearchPaginating.value = false

  if (!hasEnoughVisibleProducts.value && apiNext.value) {
    await paginateProductsSearch()
  }
}
</script>
