<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-row :gutter="20">
      <el-col :xs="20" :md="20">
        <el-row>
          <el-col :xs="24" :md="8">
            <!-- Bank name -->
            <el-form-item
              prop="name"
              class="mr-4 mb-3 flex-shrink-0"
              :label="isFirstRow ? $t('common.forms.labels.name') : null"
            >
              <el-input id="name" v-model="form.name" class="w-full" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="8">
            <!-- iban -->
            <el-form-item
              prop="iban"
              class="mr-4 mb-3 flex-shrink-0"
              :label="isFirstRow ? $t('common.forms.labels.iban') : null"
            >
              <el-input id="iban" v-model="form.iban" class="w-full" />
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="8">
            <!-- swift -->
            <el-form-item
              prop="swift"
              class="mr-4 mb-3 flex-shrink-0"
              :label="isFirstRow ? $t('common.forms.labels.swift') : null"
            >
              <el-input id="swift" v-model="form.swift" class="w-full" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
      <el-col :xs="4" :md="4">
        <div :class="{ 'mt-th-input-title': isFirstRow }">
          <!-- Delete -->
          <el-button
            icon="Delete"
            type="text"
            class="-ml-3"
            :title="$t('common.interactions.buttons.remove')"
            @click="$emit('remove-item')"
          />

          <!-- Add -->
          <el-button
            v-if="isLastRow"
            type="primary"
            plain
            class="el-button--primary-icon"
            icon="Plus"
            :title="$t('common.interactions.buttons.add')"
            @click="$emit('add-item')"
          />
        </div>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { isValidIban, isValidSwift } from './helpers'
export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    isFirstRow: {
      type: Boolean,
      default: false
    },
    isLastRow: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    isFieldsRequired() {
      return !!Object.values(this.form).find((value) => !!value)
    },
    rules() {
      return {
        name: [
          {
            required: this.isFieldsRequired,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          },
          {
            max: 512,
            message: this.$t('common.forms.rules.max_length', { length: 512 }),
            trigger: ['blur', 'change']
          }
        ],
        iban: [
          {
            required: this.isFieldsRequired,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          },
          {
            validator: this.validateIBAN,
            trigger: ['blur', 'change']
          }
        ],
        swift: [
          {
            validator: this.validateSWIFT,
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  methods: {
    validateIBAN(rule, value, cb) {
      if (!this.isFieldsRequired || isValidIban(value)) {
        cb()
      } else {
        cb(new Error(this.$t('common.forms.rules.field_warnings.iban_format')))
      }
    },
    validateSWIFT(rule, value, cb) {
      const isValid = isValidSwift(value)
      if (!value || isValid) {
        cb()
      } else {
        cb(new Error(this.$t('common.forms.rules.field_warnings.swift_format')))
      }
    },
    validate() {
      return new Promise((resolve) => {
        this.$refs['form'].validate(resolve)
      })
    }
  }
}
</script>
