<template>
  <el-row :gutter="20">
    <el-col v-for="(item, index) in form" :key="index" :xs="24" :md="12">
      <!-- Address information -->
      <address-row
        v-model="form[index]"
        @update:modelValue="form[index] = $event"
        @delete="removeItem(index)"
      />
    </el-col>
    <!-- Add -->
    <el-col :xs="24">
      <el-button
        v-if="canAddItem"
        text
        class="block -mt-2"
        plain
        @click="addItem"
      >
        <el-icon><Plus /></el-icon>
        <span>
          {{ $t('pages.customers.edit.form.properties.addresses.add') }}
        </span>
      </el-button>
    </el-col>
  </el-row>
</template>

<script>
import AddressRow from '@/components/tillhub/addresses-input-v2/address/address-row'
import { genInitialAddress } from '@/utils/address'
import { addressTypes } from '../helpers'

export default {
  components: { AddressRow },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    },
    translations() {
      return {
        default: this.$t('common.forms.labels.addresses.types.local'),
        returns: this.$t('common.forms.labels.addresses.types.returns')
      }
    },
    usedAddressTypes() {
      return this.form.map(({ type }) => type)
    },
    canAddItem() {
      return this.usedAddressTypes.length < this.availableTypes.length
    },
    availableTypes() {
      return addressTypes.map((value) => {
        return {
          value,
          label: this.translations[value],
          disabled: this.usedAddressTypes.includes(value)
        }
      })
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        if (!this.form.length) this.addItem()
      }
    }
  },
  methods: {
    removeItem(index) {
      this.form.splice(index, 1)
      if (this.form.length === 0) this.addItem()
    },
    addItem() {
      this.form.push({ ...genInitialAddress(), type: this.getAddressType() })
    },
    getAddressType() {
      return addressTypes.find((type) => !this.usedAddressTypes.includes(type))
    }
  }
}
</script>
