<template>
  <th-wrapper class="m-6" :title="$t('common.titles.general_info.title')">
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :md="12">
          <!-- company name -->
          <el-col>
            <el-form-item
              prop="companyName"
              :label="$t('common.forms.labels.company_name')"
            >
              <el-input
                id="companyName"
                v-model="companyName"
                :placeholder="$t('common.forms.labels.company_name')"
              />
            </el-form-item>
          </el-col>
          <!-- number -->
          <el-col>
            <el-form-item
              prop="number"
              :label="$t('pages.suppliers.form.placeholder.supplier_number')"
            >
              <el-input
                id="number"
                v-model="number"
                :disabled="generateSuppliersNumber"
                :placeholder="
                  generateSuppliersNumber
                    ? $t('pages.staff.form.hint.staff_id.is_auto')
                    : $t('pages.suppliers.form.placeholder.supplier_number')
                "
              />
            </el-form-item>
          </el-col>
          <!-- payment terms -->
          <el-col>
            <el-form-item
              prop="paymentTerms"
              :label="$t('pages.suppliers.form.placeholder.payment_terms')"
            >
              <th-number-input
                id="paymentTerms"
                v-model.number="paymentTerms"
                positive-only
                :locale="$i18n.locale"
                :placeholder="
                  $t('pages.suppliers.form.placeholder.payment_terms')
                "
              />
            </el-form-item>
          </el-col>
          <!--  accounts receivable -->
          <el-col>
            <el-form-item
              prop="accountsReceivable"
              :label="$t('pages.suppliers.form.placeholder.account_receivable')"
            >
              <el-select
                id="accountsReceivable"
                v-model="accountsReceivable"
                class="w-full"
                :placeholder="
                  $t('pages.suppliers.form.placeholder.account_receivable')
                "
              >
                <el-option
                  v-for="(account, index) in accountsReceivableList"
                  :id="account.value"
                  :key="index"
                  :label="account.label"
                  :value="account.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <!-- account payable -->
          <el-col>
            <el-form-item
              prop="accountsPayable"
              :label="$t('pages.suppliers.form.placeholder.account_payable')"
            >
              <el-select
                id="accountsPayable"
                v-model="accountsPayable"
                class="w-full"
                :placeholder="
                  $t('pages.suppliers.form.placeholder.account_payable')
                "
              >
                <el-option
                  v-for="(account, index) in accountsPayableList"
                  :id="account.value"
                  :key="index"
                  :label="account.label"
                  :value="account.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :md="12">
          <!-- description -->
          <el-col>
            <el-form-item
              prop="description"
              :label="$t('common.titles.description')"
            >
              <el-input
                id="description"
                v-model="description"
                :placeholder="$t('common.titles.description')"
              />
            </el-form-item>
          </el-col>
          <!-- GLN number -->
          <el-col>
            <el-form-item
              prop="glnNumber"
              :label="$t('pages.suppliers.form.placeholder.gln_number')"
            >
              <el-input
                id="glnNumber"
                v-model="glnNumber"
                :placeholder="$t('pages.suppliers.form.placeholder.gln_number')"
              />
            </el-form-item>
          </el-col>
          <!-- tax number -->
          <el-col>
            <el-form-item
              prop="taxNumber"
              :label="$t('pages.suppliers.form.placeholder.tax_number')"
            >
              <el-input
                id="taxNumber"
                v-model="taxNumber"
                :placeholder="$t('pages.suppliers.form.placeholder.tax_number')"
              />
            </el-form-item>
          </el-col>
          <!-- tax subject -->
          <el-col>
            <el-form-item
              prop="taxSubject"
              :label="$t('pages.suppliers.form.placeholder.tax_subject')"
            >
              <el-switch id="taxSubject" v-model="taxSubject" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import remove from 'just-remove'
import { mapGetters } from 'vuex'
import { createAccountOptions } from '../helpers'

export default {
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      generateSuppliersNumber: false
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    companyName: {
      get() {
        return this.modelValue.companyName
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          companyName: value
        })
      }
    },
    number: {
      get() {
        return this.modelValue.number
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          number: value
        })
      }
    },
    paymentTerms: {
      get() {
        return this.modelValue.paymentTerms
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          paymentTerms: value
        })
      }
    },
    accountsReceivable: {
      get() {
        return this.modelValue.accountsReceivable?.[0] || null
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          accountsReceivable: [value]
        })
      }
    },
    accountsPayable: {
      get() {
        return this.modelValue.accountsPayable?.[0] || null
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          accountsPayable: [value]
        })
      }
    },
    description: {
      get() {
        return this.modelValue.description
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          description: value
        })
      }
    },
    glnNumber: {
      get() {
        return this.modelValue.glnNumber
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          glnNumber: value
        })
      }
    },
    taxNumber: {
      get() {
        return this.modelValue.taxNumber
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          taxNumber: value
        })
      }
    },
    taxSubject: {
      get() {
        return this.modelValue.taxSubject
      },
      set(value) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          taxSubject: value
        })
      }
    },
    form() {
      return {
        companyName: this.companyName,
        number: this.number,
        paymentTerms: this.paymentTerms,
        accountsReceivable: this.accountsReceivable,
        accountsPayable: this.accountsPayable,
        description: this.description,
        glnNumber: this.glnNumber,
        taxNumber: this.taxNumber,
        taxSubject: this.taxSubject
      }
    },
    rules() {
      return {
        companyName: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ],
        number: [
          {
            required: !this.generateSuppliersNumber,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: ['blur', 'change']
          }
        ]
      }
    },
    accountsReceivableList() {
      return createAccountOptions(this.resources.accounts || [])
    },
    accountsPayableList() {
      return createAccountOptions(this.resources.expenseAccounts || [])
    }
  },
  async mounted() {
    await this.fetchConfiguration()
  },
  methods: {
    validate(cb) {
      this.$refs.form.validate(cb)
    },
    async fetchConfiguration() {
      const { data } = await th.inventoryConfiguration().getAll()
      const config = data?.[0]
      if (config) this.generateSuppliersNumber = config.autoGenerate
    }
  }
}
</script>
