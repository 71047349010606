<template>
  <el-form
    ref="form"
    class="th-table-responsive bg-th-primary-light"
    data-testid="product-add-row"
    @submit.prevent="submitForm"
  >
    <table class="th-table">
      <thead>
        <tr>
          <th class="py-0">
            <div :style="{ 'min-width': productColWidth }" />
          </th>
          <th class="min-w-field py-0 w-40" />
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <el-form-item
              :label="$t('pages.supplier.products.all.table.product')"
            >
              <!-- product number + product name  -->
              <product-search-select
                v-model="products"
                class="w-full"
                :result-filter="filterOutChosenProducts"
              />
            </el-form-item>
          </td>

          <td>
            <el-form-item label="&nbsp;">
              <el-button
                class="w-32"
                data-testid="product-add-button"
                type="primary"
                plain
                native-type="submit"
                :disabled="!products.length"
                v-text="$t('common.interactions.buttons.add')"
              />
            </el-form-item>
          </td>
        </tr>
      </tbody>
    </table>
  </el-form>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue'
import th from '@tillhub/javascript-sdk'
import flatten from 'just-flatten-it'
import ProductSearchSelect from './product-search-select'

const props = defineProps({
  existingProductIds: {
    type: Array,
    default: () => []
  },
  productColWidth: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['added'])

const products = ref([])

async function submitForm() {
  const promises = products.value.map((product) => {
    if (product.type === 'variant_product') {
      return th
        .products()
        .getChildrenDetails(product.id, true)
        .then(({ data }) => [product, ...data])
    }
    // We're adding regular products to that promises chain to keep products order
    // We want product variants to be added right after their parents
    return Promise.resolve([product])
  })

  const result = flatten(await Promise.all(promises))

  emit('added', result)
  reset()
}

function filterOutChosenProducts(products) {
  return products.filter(
    (product) => !props.existingProductIds.includes(product.id)
  )
}

function reset() {
  products.value = []
}
</script>

<style scoped>
.th-table td {
  padding-top: 0;
}
</style>
