<template>
  <th-wrapper
    :title="$t('pages.supplier.products.title')"
    :info="$t('pages.supplier.products.info')"
    body-class="p-0"
    class="m-6 border"
    header-class="bg-th-primary-light pb-4"
  >
    <el-form>
      <!-- Additional header -->
      <product-add-row
        :existing-product-ids="existingProductIds"
        :product-col-width="productColWidth"
        @added="addProducts"
      />

      <!-- Table -->
      <div
        v-loading="isProductsInitLoading"
        class="th-table-responsive shadow-th-light"
      >
        <table class="th-table" data-testid="table-products">
          <thead>
            <tr>
              <th class="min-w-field py-2 h-12">
                <div
                  class="flex h-full w-full items-center"
                  :style="{ 'min-width': productColWidth }"
                >
                  <!-- Product name -->
                  <span>
                    {{ $t('pages.supplier.products.all.table.product') }}
                  </span>
                </div>
              </th>
              <th class="min-w-field py-2 h-12 w-40">
                <!-- Attributes -->
                {{ $t('pages.supplier.products.all.table.attributes') }}
              </th>
              <th class="min-w-field py-2 h-12 w-40" sortable>
                <!-- Product Number -->
                <sortable-header v-model="sort.order">
                  {{ $t('pages.supplier.products.all.table.product_number') }}
                </sortable-header>
              </th>
              <th class="min-w-field py-2 h-12 w-40">
                <!-- Type -->
                {{ $t('pages.supplier.products.all.table.type') }}
              </th>
              <th class="min-w-field py-2 h-12 w-40">
                <!-- Product Group -->
                {{ $t('pages.supplier.products.all.table.product_group') }}
              </th>
              <th class="py-2 h-12 w-0" />
            </tr>
          </thead>

          <tbody>
            <product-row
              v-for="product in childrenPageSorted"
              :key="product.id"
              :product="product"
              :product-col-width="productColWidth"
              :resources="resources"
              @delete="deleteProduct(product)"
            />
          </tbody>
        </table>

        <!-- Table no data -->
        <div
          v-if="!products || products.length === 0"
          class="py-5 px-8 text-gray-500"
        >
          {{ $t('common.data.no_data_yet') }}
        </div>

        <!-- Pagination -->
        <th-pagination
          v-if="products && products.length > 0"
          class="bg-th-primary-light"
          :page-size="pageSizes.main"
          :page-sizes="pageSizesList"
          :total="products.length"
          :current-page="page"
          @current-change="(v) => (page = v)"
          @size-change="changePageSize($event)"
        />
      </div>
    </el-form>
  </th-wrapper>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  ref,
  reactive,
  computed,
  inject,
  watch
} from 'vue'
import { useStore } from 'vuex'
import remove from 'just-remove'
import th from '@tillhub/javascript-sdk'
import ProductRow from './products/product-row.vue'
import ProductAddRow from './products/product-add-row.vue'
import SortableHeader from '@/components/sortable-header/sortable-header.vue'

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  supplierId: {
    type: String,
    default: null
  },
  resources: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['update:modelValue'])
const store = useStore()
const logException = inject('logException')

const page = ref(1)
const productColWidth = ref('15rem')
const products = ref([])
const isProductsInited = ref(false)
const isProductsInitLoading = ref(false)
const pageSizesList = ref([10, 20, 30, 40, 50, 100])
const sort = reactive({
  field: 'product_number',
  order: null
})

const pageSizes = computed(() => store.getters['Config/getPageSizes'])
const childrenPage = computed(() =>
  products.value.slice(
    (page.value - 1) * pageSizes.value.main,
    page.value * pageSizes.value.main
  )
)
const childrenPageSorted = computed(() => {
  if (sort.field === 'product_number' && sort.order !== null) {
    const sortedList = childrenPage.value.slice().sort((a, b) => {
      const customIdA = String(a.custom_id || '')
      const customIdB = String(b.custom_id || '')
      return customIdA.localeCompare(customIdB)
    })
    if (sort.order === 'desc') {
      return sortedList.reverse()
    }
    return sortedList
  }
  return childrenPage.value
})

const existingProductIds = computed(() => products.value.map((p) => p.id))

watch(
  () => props.supplierId,
  () => initProducts(),
  { immediate: true }
)

watch(
  () => props.modelValue,
  () => ensureProductsConsistency(),
  { immediate: true }
)
watch(childrenPage.value, () => {
  // Update the page in case of deleting all the products of a page
  if (!childrenPage.value.length && page.value !== 1) {
    page.value = page.value - 1
  }
})

async function initProducts() {
  if (!props.supplierId || isProductsInited.value) return

  isProductsInitLoading.value = true

  const action = () =>
    th.products().getAll({
      business_partner_id: props.supplierId,
      limit: 200
    })
  products.value = await paginateProductsInit(action)

  isProductsInitLoading.value = false
  isProductsInited.value = true
}

async function paginateProductsInit(action, dataInherited = []) {
  const { data, next } = await action()
  const result = [...dataInherited, ...data]
  if (next) {
    return await paginateProductsInit(next, result)
  }
  return result
}

async function ensureProductsConsistency() {
  if (!isProductsInited.value) return

  const productsIdsToRemove = remove(existingProductIds.value, props.modelValue)
  const productsIdsToInit = remove(props.modelValue, existingProductIds.value)

  products.value = products.value.filter(
    (p) => !productsIdsToRemove.includes(p.id)
  )

  // That single products fetching probably won't ever trigger in the current flow.
  // But I kept it there because it's ensuring the we have the pruducts list up to date
  // in case products will be added from somewhere else outside of this component
  const promises = productsIdsToInit.map((id) => {
    return getSingleProduct(id)
  })
  const productsToAdd = await Promise.all(promises)

  // theoretically there could be null-s
  products.value.push(...productsToAdd.filter((p) => p))
}

async function getSingleProduct(id) {
  try {
    const { data } = await th.products().get(id)
    if (data) {
      return data
    } else {
      logException(new Error(`product if ID ${id} not found`))
    }
  } catch (error) {
    logException(error)
  }
  return null
}

function addProducts(productsToAdd) {
  const productIds = productsToAdd.map((p) => p.id)
  // We need this because variant parent could be delated separately from children
  // and adding that parent again would cause children duplication
  const productsDeduplicated = products.value.filter(
    (p) => !productIds.includes(p.id)
  )
  products.value = [...productsDeduplicated, ...productsToAdd]
  emit(
    'update:modelValue',
    products.value.map((p) => p.id)
  )
}

function deleteProduct(product) {
  const productIds = props.modelValue.filter((id) => id !== product.id)
  emit('update:modelValue', productIds)
}

function changePageSize(value) {
  store.dispatch('Config/setLocalConfigurationValue', {
    path: 'pageSizes.main',
    value
  })
}
</script>

<style scoped>
.search-in-table :deep(.el-input__prefix) {
  left: 3px;
}

.search-in-table :deep(.el-input__inner) {
  padding-left: 25px !important;
  font-size: 12px;
  line-height: 1.2;
}

.search-in-table :deep(.el-input__prefix svg) {
  height: 18px !important;
  width: 18px !important;
  fill: currentColor;
}
</style>
