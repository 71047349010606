<template>
  <div>
    <div v-for="(item, index) in localValue" :key="index">
      <bank-inputs-row
        :ref="'bank-' + index"
        v-model="localValue[index]"
        :is-first-row="index === 0"
        :is-last-row="index === localValue.length - 1"
        @add-item="addItem"
        @remove-item="removeItem(index)"
      />
    </div>
  </div>
</template>

<script>
import BankInputsRow from './bank-inputs-row'
import { genInitialBank } from './helpers'

export default {
  components: {
    BankInputsRow
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      localValue: []
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.localValue = [...this.modelValue]
        if (!this.localValue.length) this.addItem()
      }
    }
  },
  methods: {
    removeItem(index) {
      this.localValue.splice(index, 1)
      this.$emit('update:modelValue', this.localValue)
    },
    addItem() {
      this.localValue.push(genInitialBank())
      this.$emit('update:modelValue', this.localValue)
    },
    async validate() {
      // Validate all bank rows
      let valid = true
      for (const key in this.$refs) {
        if (key.includes('bank') && this.$refs[key].length > 0) {
          if (!(await this.$refs[key][0].validate())) valid = false
        }
      }
      return valid
    }
  }
}
</script>
