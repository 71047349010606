<template>
  <th-wrapper
    collapsable
    class="m-6"
    :title="$t('pages.suppliers.form.headers.contact')"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-row :gutter="10">
        <el-col :md="8">
          <!-- first name -->
          <el-form-item
            prop="firstName"
            :label="$t('common.forms.labels.firstname')"
          >
            <el-input
              id="firstName"
              v-model="form.firstName"
              :placeholder="$t('common.forms.labels.firstname')"
            />
          </el-form-item>
        </el-col>
        <!-- last name -->
        <el-col :md="8">
          <el-form-item
            prop="lastName"
            :label="$t('common.forms.labels.lastname')"
          >
            <el-input
              id="lastName"
              v-model="form.lastName"
              :placeholder="$t('common.forms.labels.lastname')"
            />
          </el-form-item>
        </el-col>
        <!-- email -->
        <el-col :md="8">
          <el-form-item prop="email" :label="$t('common.forms.labels.email')">
            <el-input
              id="email"
              v-model="form.email"
              :placeholder="$t('common.forms.labels.email')"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- Phone numbers -->
      <phone-inputs
        ref="supplier-contact-phones"
        v-model="form.phoneNumbers"
        class="mb-4"
        :phone-types="phoneTypes"
      />

      <!-- Addresses -->
      <supplier-contact-address
        ref="supplier-contact-address"
        v-model="form.addresses"
      />
    </el-form>
  </th-wrapper>
</template>

<script>
import PhoneInputs from '@/components/contact-details/phones'
import SupplierContactAddress from './supplier-contact-address'
import { SUPPLIERS_PHONE_TYPES } from '@/constants'

export default {
  components: { PhoneInputs, SupplierContactAddress },

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    form: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    phoneTypes() {
      return Object.values(SUPPLIERS_PHONE_TYPES)
    },
    rules() {
      return {
        email: {
          type: 'email',
          message: this.$t(
            'pages.customers.edit.form.field_warnings.type.email'
          )
        }
      }
    }
  },
  methods: {
    handleSubmit() {
      this.form = this.$refs['supplier-contact-phones'].transformData()
    },
    validate(cb) {
      this.$refs['form'].validate(cb)
    }
  }
}
</script>
