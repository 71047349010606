<template>
  <tr class="border-b align-middle">
    <!-- Product name -->
    <td
      ref="productTextCell"
      class="overflow-hidden"
      :style="{ 'max-width': productColWidth, 'text-overflow': 'ellipsis' }"
    >
      <el-tooltip effect="dark" placement="top" :content="product.name">
        <span>
          {{ name }}
        </span>
      </el-tooltip>
    </td>

    <!-- Attributes  -->
    <td>
      {{ attributes }}
    </td>

    <!-- Product Number  -->
    <td>
      {{ productNumber }}
    </td>

    <!-- Type -->
    <td>
      {{ type }}
    </td>

    <!-- Product Group -->
    <td>
      {{ productGroup }}
    </td>

    <td class="text-left">
      <!-- Delete -->
      <div class="w-12">
        <el-button
          icon="Delete"
          class="el-button--text-icon"
          @click="emit('delete')"
        />
      </div>
    </td>
  </tr>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { isPurchaseItem } from '@/utils/products'

const props = defineProps({
  product: {
    type: Object,
    required: true
  },
  productColWidth: {
    type: String,
    default: ''
  },
  resources: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['delete'])

const { t } = useI18n()

const translations = {
  voucher: t('common.products.types.voucher.title'),
  variant_product: t('common.products.types.variant_product.title'),
  variant: t('common.products.types.variant.title'),
  product: t('common.products.types.product.title'),
  linked_product: t('common.products.types.linked_product.title'),
  linked: t('common.products.types.linked.title'),
  composed_product: t('common.products.types.composed_product.title')
}

const name = computed(() => props.product.name || '--')
const attributes = computed(() => {
  // Add attributes list for Variants
  if (
    props.product.attributes &&
    Object.keys(props.product.attributes).length
  ) {
    let attributes = []
    Object.keys(props.product.attributes).forEach((option) => {
      attributes = attributes.concat(props.product.attributes[option])
    })
    return attributes.join(' | ')
  }

  // Add attributes list for Non Variants
  if (props.product.options?.length) {
    let attributes = []
    props.product.options.forEach((option) => {
      Object.keys(option).forEach((attribute) => {
        attributes = attributes.concat(option[attribute])
      })
    })
    // Filter is a fix for null attributes
    // https://tillhub.atlassian.net/browse/DAS-1264
    return attributes.filter((attribute) => attribute).join(' | ')
  }

  return '--'
})
const productNumber = computed(() => props.product.custom_id || '--')
const type = computed(() => {
  if (props.product.type) {
    if (isPurchaseItem(props.product)) {
      return t('pages.products.edit.form.types.purchase_item')
    }
    return translations[props.product.type] || '--'
  }
  return '--'
})
const productGroup = computed(() => {
  if (props.product.product_group && props.resources.product_groups) {
    const productGroup =
      props.resources.product_groups.find(
        (pgItem) => pgItem.id === props.product.product_group
      ) || {}
    return productGroup.name || '--'
  }
  return '--'
})
</script>
